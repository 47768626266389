<template>
  <div class="flex flex-col items-center md:space-x-4 md:flex-row">
    <div class="flex mb-8 space-x-3 md:mb-0">
      <NuxtLink v-for="(link, i) in shareLinks"
                :key="i"
                class="relative flex items-center justify-center mx-1.5 border border-white rounded-full w-14 h-14 lg:mx-0 flex-shrink-0 box-border group"
                target="_blank"
                :to="link.url"
                @click="handleClick(link.label)"
      >
        <span class="sr-only">{{ link.label }}</span>
        <component :is="`Icon${link.icon}`"
                   class="h-5"
        />
        <span class="absolute transition-opacity duration-200 rounded-full opacity-0 inset-[-1px] bg-pink-blue mix-blend-multiply group-hover:opacity-100" />
      </NuxtLink>
    </div>

    <BlogButtonCopy :url="url"
                    type="dark"
                    @click="handleClick('Copy link share')"
    />
  </div>
</template>

<script setup lang="ts">
import useShareLinks from '../../composables/share-links';
import { useRuntimeConfig } from '#app';
import { useRoute } from 'vue-router';
import { useNuxtApp } from '#imports';

const runtimeConfig = useRuntimeConfig();
const route = useRoute();
const { $event } = useNuxtApp();

const url = runtimeConfig.public.domain + route.path;
const shareLinks = useShareLinks({ url });

function handleClick(linkLabel: string) {
  $event('Sharing', { props: { [linkLabel]: route.path } });
}
</script>
